export const size = {
  xs: '10px',
  sm: '12px',
  hecto: '14px',
  kilo: '16px',
  giga: '20px',
  tera: '24px',
  zetta: '32px',
  bronto: '48px'
};
